import { faTools, faUniversity, faAsterisk, faCoins, faTree, faChartBar, faMagnifyingGlassChart, faBriefcase} from "@fortawesome/free-solid-svg-icons"

import CILocalEconomyDashboard from "../pages/dashboard/CollegeInsight/1_CILocalEconomyDashboard"
import RegionalContextDashboard from "../pages/dashboard/CollegeInsight/2_RegionalContextDashboards"
import CollegeContextDashboard from "../pages/dashboard/CollegeInsight/3_CollegeContextDashboards"
import SSAAnalysisDashboard from "../pages/dashboard/CollegeInsight/4_SSAAnalysisDashboards"
import EILocalEconomyDashboard  from "../pages/dashboard/EconomyInsight/1_EILocalEconomyDashboard"
import BenchmarkAnalysisDashboard from "../pages/dashboard/EconomyInsight/2_BenchmarkAnalysisDashboard"
import IndustryAnalysisDashboard from "../pages/dashboard/EconomyInsight/3_IndustryAnalysisDashboard"
import EuropeanGreenNewDealDashboard from "../pages/dashboard/Sustainability Insight/EuropeanGreenNewDealDashboard"
import GreenShareEuropeDashboard from "../pages/dashboard/Sustainability Insight/GreenShareEuropeDashboard"
import GreenIntensiveRoleDashboard from "../pages/dashboard/Sustainability Insight/GreenIntensivRolesDashboard"
import ETF_DataQuality from "../pages/dashboard/ETF/ETF_DataQuality"
import ETF_Africa from "../pages/dashboard/ETF/ETF_Africa"
import ETF_Egypt from "../pages/dashboard/ETF/ETF_Egypt"
import ETF_Egypt_Arabic from "../pages/dashboard/ETF/ETF_Egypt_Arabic"
import ETF_Georgia from "../pages/dashboard/ETF/ETF_Georgia"
import ETF_Kenya from "../pages/dashboard/ETF/ETF_Kenya"
import ETF_Morocco from "../pages/dashboard/ETF/ETF_Morocco"
import ETF_Tunisia from "../pages/dashboard/ETF/ETF_Tunisia"
import ETF_UkraineLvivProject from "../pages/dashboard/ETF/ETF_UkraineLvivProject"
import ETF_UkrainePES from "../pages/dashboard/ETF/ETF_UkrainePES"
import ETF_Ukraine from "../pages/dashboard/ETF/ETF_Ukraine"
import ART_ER_Skills_Intelligence from "../pages/dashboard/ART-ER/ART_ER_Skills_Intelligence"
import ART_ER_FULL from "../pages/dashboard/ART-ER/ART_ER_FULL"
import EuropeanAnalysis from "../pages/dashboard/EuropeanAnalysis/EuropeanAnalysis"
import Confindustria_BS from "../pages/dashboard/Confindustria_BS/Confindustria_BS"
import PernodRicard from "../pages/dashboard/PernodRicard/PernodRicard"
import UnipdAgraria from "../pages/dashboard/Unipd/UnipdAgraria"
import DBAudioTechnik from "../pages/dashboard/DBAudioTechnik/DBAudioTechnik"
import DBSkillsCatalogue from "../pages/dashboard/DBAudioTechnik/DBSkillsCatalogue"
import Confindustria_BG from "../pages/dashboard/Confindustria_BG/Confindustria_BG"
import FILIC from "../pages/dashboard/FILIC/FILIC"
import TalentsVenture from "../pages/dashboard/TalentsVenture/TalentsVenture"
import ART_ER_Skills_Intelligence_NEW from "../pages/dashboard/ART-ER/ART_ER_Skills_Intelligence_NEW"
import OsservatorioMercatoLavoro from "../pages/dashboard/EU_Analysis/OsservatorioMercatoLavoro"
import ObservatorioMercadoTrabajo from "../pages/dashboard/EU_Analysis/ObservatorioMercadoTrabajo"
import Novartis from "../pages/dashboard/Novartis/Novartis"
import UnioneIndustrialiTorino from "../pages/dashboard/UnioneIndustrialiTorino/UnioneIndustrialiTorino"
import UniversitàDiTrento from "../pages/dashboard/UniversitàDiTrento/UniversitàDiTrento"
import WorkforceIndex from "../pages/dashboard/WI-DEMO/WorkforceIndex"
import EHL from "../pages/dashboard/EHL/EHL"
import Zukunftsagenten from "../pages/dashboard/Zukunftsagenten/Zukunftsagenten"
import LMI_Morocco from "../pages/dashboard/LMI_Morocco/LMI_Morocco"
import Analisi_Annunci from "../pages/dashboard/Analisi_Annunci/Analisi_Annunci"
import Analisi_Stage from "../pages/dashboard/Analisi_Stage/Analisi_Stage"
import Analisi_Stage_Attivati from "../pages/dashboard/Analisi_Stage_Attivati/Analisi_Stage_Attivati"
import ETF_Green_Dashboard from "../pages/dashboard/ETF/ETF_Green_Dashboard"
import Aviva from "../pages/dashboard/Aviva/Aviva"
import ETF_Morocco_French from "../pages/dashboard/ETF/ETF_Morocco_French"
import ETF_Tunisia_French from "../pages/dashboard/ETF/ETF_Tunisia_French"
import Digital_Dashboard from "../pages/dashboard/Digital_Dashboard/Digital_Dashboard"
import Analisi_Annunci_New from "../pages/dashboard/Analisi_Annunci_New/Analisi_Annunci_New"
import Analisi_Stage_New from "../pages/dashboard/Analisi_Stage_New/Analisi_Stage_New"
import Analisi_Stage_Attivati_New from "../pages/dashboard/Analisi_Stage_Attivati_New/Analisi_Stage_Attivati_New"
import Training_Monitoring from "../pages/dashboard/Training_Monitoring/Training_Monitoring"



export const SolutionGroups = [
    {
        "name": "ART-ER",
        "icon": faTools
    },
    {
        "name": "Aviva",
        "icon": faChartBar
    },
    {
        "name": "Confindustria",
        "icon": faCoins
    },
    {
        "name" : "Curriculum Insight",
        "icon" : faUniversity
    },
    {
        "name": "d&b",
        "icon": faTools
    },
    {
        "name": "Economy Insight",
        "icon": faCoins
    },
    {
        "name": "EFE",
        "icon": faChartBar
    },
    {
        "name": "EHL",
        "icon": faChartBar
    },
    {
        "name": "ETF",
        "icon": faTree
    },
    {
        "name": "EU Analysis",
        "icon": faChartBar
    }, 
    {
        "name": "European Analysis",
        "icon": faTools
    },
    {
        "name" : "Examples",
        "icon" : faAsterisk
    },
    {
        "name": "FILIC",
        "icon": faChartBar
    },
    {
        "name": "Novartis",
        "icon": faChartBar
    }, 
    {
        "name" : "Pernod Ricard",
        "icon" : faAsterisk
    },
    {
        "name": "Politecnico di Milano",
        "icon": faUniversity
    },
    {
        "name": "Sustainability Insight",
        "icon": faTree
    },
    {
        "name" : "Tableau Demo",
        "icon" : faTools
    },
    {
        "name": "Talents Venture",
        "icon": faUniversity
    }, 
    {
        "name": "Travi",
        "icon": faBriefcase
    }, 
    {
        "name": "Unipd",
        "icon": faUniversity
    },
    {
        "name": "Unione Industriali Torino",
        "icon": faChartBar
    },
    {
        "name": "Università di Trento",
        "icon": faUniversity
    },
    {
        "name": "Workforce Index",
        "icon": faTools
    },
    {
        "name": "Zukunftsagenten",
        "icon": faChartBar
    },
    {
        "name": "Try our data",
        "icon": faMagnifyingGlassChart
    }
];


export const DashboardMapping = {
    private: {
        "CI_1": CILocalEconomyDashboard,
        "CI_2": RegionalContextDashboard,
        "CI_3": CollegeContextDashboard,
        "CI_4": SSAAnalysisDashboard,
        "EI_1": EILocalEconomyDashboard,
        "EI_2": BenchmarkAnalysisDashboard,
        "EI_3": IndustryAnalysisDashboard,
        "SI_1": EuropeanGreenNewDealDashboard,
        "SI_2": GreenShareEuropeDashboard,
        "SI_3": GreenIntensiveRoleDashboard,
        "EUA": EuropeanAnalysis,
        "ETF_QUALITY": ETF_DataQuality,
        "ETF_AF": ETF_Africa,
        "ETF_EG": ETF_Egypt,
        "ETF_EG_AR": ETF_Egypt_Arabic,
        "ETF_GE": ETF_Georgia,
        "ETF_G_D": ETF_Green_Dashboard,
        "ETF_KE": ETF_Kenya,
        "ETF_MA": ETF_Morocco,
        "ETF_MA_FR": ETF_Morocco_French,
        "ETF_TN": ETF_Tunisia,
        "ETF_TN_FR": ETF_Tunisia_French,
        "ETF_UA_LVIV": ETF_UkraineLvivProject,
        "ETF_UA_PES": ETF_UkrainePES,
        "ETF_UA": ETF_Ukraine,
        "ART_ER": ART_ER_Skills_Intelligence,
        "ART_ER_NEW": ART_ER_Skills_Intelligence_NEW,
        "ART_ER_FULL": ART_ER_FULL,
        "AVIVA": Aviva,
        "DIGITAL_DASHBOARD": Digital_Dashboard,
        "CONFINDUSTRIA_BG": Confindustria_BG,
        "CONFINDUSTRIA_BS": Confindustria_BS,
        "PERNOD_RICARD": PernodRicard,
        "UNIPD_AGRARIA": UnipdAgraria,
        "DB_AUDIO": DBAudioTechnik,
        "DB_CATALOGUE": DBSkillsCatalogue,
        "FILIC": FILIC,
        "TALENTS_VENTURE": TalentsVenture,
        "TRAINING_MONITORING": Training_Monitoring,
        "ILMI": OsservatorioMercatoLavoro,
        "SLMI": ObservatorioMercadoTrabajo,
        "LMI_MOROCCO": LMI_Morocco,
        "NOVARTIS": Novartis,
        "UNIONE_INDUSTRIALI_TORINO": UnioneIndustrialiTorino,
        "UNIVERSITA_DI_TRENTO": UniversitàDiTrento,
        "WORKFORCE_INDEX": WorkforceIndex,
        "EHL": EHL,
        "ZUKUNFTSAGENTEN": Zukunftsagenten,
        "ANALISI_ANNUNCI": Analisi_Annunci,
        "ANALISI_STAGE": Analisi_Stage,
        "ANALISI_STAGE_ATTIVATI": Analisi_Stage_Attivati,
        "ANALISI_ANNUNCI_NEW": Analisi_Annunci_New,
        "ANALISI_STAGE_NEW": Analisi_Stage_New,
        "ANALISI_STAGE_ATTIVATI_NEW": Analisi_Stage_Attivati_New,
        "LG_EU": "https://europe-insights.lightcast.io/"
    },
    public: {}
};



export const Languages = [
    {lang: "EN", flag: "united-kingdom.svg", locale: "en-GB"}
    // {lang: "IT", flag: "italy.svg", locale: "it-IT"}
];

